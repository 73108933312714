<template>
    <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form" style="max-width: 500px; margin: 40px auto 0;">
      <a-form-item label="营业执照">
        <a-upload
          name="photo"
          class="yyzz-wrapper"
          :show-upload-list="false"
          action="/common/upload"
          @change="handleChange"
          v-decorator="['yyzz', {initialValue: yyzz, rules: [{required: true, message: '请上传营业执照'}]}]">
          <img v-if="yyzz" :src="yyzz" alt="营业执照" />
          <div class="empty" v-else>
            <a-icon class="icon" :type="loading ? 'loading' : 'picture'" />
            <div class="upload-text">点击上传图片</div>
            <div class="upload-text-tip">支持jpg/png格式，不超过2MB</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button size="large" type="primary" @click="prevStep">上一步</a-button>
        <a-button style="margin-left:8px;" size="large" type="primary" @click="nextStep">立即注册</a-button>
      </a-form-item>
    </a-form>
</template>

<script>
export default {
  name: 'Step1',
  data () {
    return {
      yyzz: '',
      loading: false,
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this)
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    this.yyzz = this.formData.yyzz || ''
  },
  methods: {
    prevStep () {
      this.$emit('prevStep')
    },
    nextStep () {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.yyzz = this.yyzz
          this.$emit('finish', values)
        }
      })
    },
    handleChange(res) {
      if (res.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (res.file.status === 'done') {
        this.yyzz = res.file.response.info
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.yyzz-wrapper /deep/ > .ant-upload {
  width: 385px;
  height: 275px;
  border: 2px dotted #f6f6f6;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 6px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .empty {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #666;
    background-color: #fff;
    .icon {
      font-size: 40px;
    }
    .upload-text {
      font-size: 16px;
    }
    .upload-text-tip {
      color: #999;
      font-size: 12px;
    }
  }
}
</style>
