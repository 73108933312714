<template>
    <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form" style="max-width: 500px; margin: 40px auto 0;">
      <a-form-item label="企业联系人" help="该信息用于接收系统升级功能改变信息">
        <a-form-item style="width:50%;display:inline-block;">
          <a-input
            type="text"
            placeholder="请输入姓名"
            v-decorator="['yq_name', {initialValue: stepData.yq_name, rules: [{required: true, message: '请输入企业联系人姓名'}]}]"
          />
        </a-form-item>
        <a-form-item style="width:50%;display:inline-block;">
          <a-input
            type="text"
            placeholder="请输入联系电话"
            v-decorator="['yq_phone', {initialValue: stepData.yq_phone, rules: [{required: true, message: '请输入企业联系人电话'}]}]"
          />
        </a-form-item>
        <!-- <a-form-item style="width:100%;display:inline-block;">
          <a-input
            type="text"
            placeholder="请输入电子邮箱"
            v-decorator="['yq_email', {initialValue: stepData.yq_email, rules: [{required: true, message: '请输入企业联系人电子邮箱'}]}]"
          />
        </a-form-item> -->
      </a-form-item>
      <a-form-item label="财务联系人" help="该信息用于接收系统税务预警信息">
        <a-form-item style="width:50%;display:inline-block;">
          <a-input
            type="text"
            placeholder="请输入姓名"
            v-decorator="['cw_name', {initialValue: stepData.cw_name, rules: [{required: true, message: '请输入财务联系人姓名'}]}]"
          />
        </a-form-item>
        <a-form-item style="width:50%;display:inline-block;">
          <a-input
            type="text"
            placeholder="请输入联系电话"
            v-decorator="['cw_phone', {initialValue: stepData.cw_phone, rules: [{required: true, message: '请输入财务联系人电话'}]}]"
          />
        </a-form-item>
        <!-- <a-form-item style="width:100%;display:inline-block;">
          <a-input
            type="text"
            placeholder="请输入电子邮箱"
            v-decorator="['cw_email', {initialValue: stepData.cw_email, rules: [{required: true, message: '请输入财务联系人电子邮箱'}]}]"
          />
        </a-form-item> -->
      </a-form-item>
      <a-form-item label="邮寄地址" help="该信息用于接收发票资料凭证">
        <a-form-item style="width:50%;display:inline-block;">
          <a-input
            type="text"
            placeholder="请输入收件人姓名"
            v-decorator="['yj_name', {initialValue: stepData.yj_name, rules: [{required: true, message: '请输入收件人姓名'}]}]"
          />
        </a-form-item>
        <a-form-item style="width:50%;display:inline-block;">
          <a-input
            type="text"
            placeholder="请输入收件人电话"
            v-decorator="['yj_phone', {initialValue: stepData.yj_phone, rules: [{required: true, message: '请输入收件人电话'}]}]"
          />
        </a-form-item>
        <a-form-item style="width:100%;display:inline-block;">
          <a-input
            type="text"
            placeholder="请输入详细通讯地址"
            v-decorator="['yj_address', {initialValue: stepData.yj_address, rules: [{required: true, message: '请输入详细通讯地址'}]}]"
          />
        </a-form-item>
      </a-form-item>
      <a-form-item label="邮箱" help="该邮箱用于通知接收、报税凭证接收等用途">
        <a-input
          type="text"
          placeholder="请输入电子邮箱"
          v-decorator="['eamil', {initialValue: stepData.eamil, rules: [{required: true, message: '请输入登录账号'}]}]"
        />
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button size="large" type="primary" @click="prevStep">上一步</a-button>
        <a-button style="margin-left:8px;" size="large" type="primary" @click="nextStep">下一步</a-button>
      </a-form-item>
    </a-form>
</template>

<script>
export default {
  name: 'Step3',
  data () {
    return {
      stepData: {
        yq_name: '',
        yq_phone: '',
        // yq_email: '',
        cw_name: '',
        cw_phone: '',
        // cw_email: '',
        yj_name: '',
        yj_phone: '',
        yj_address: '',
        eamil: ''
      },
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this)
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    this.stepData = {
      yq_name: this.formData.yq_name || '',
      yq_phone: this.formData.yq_phone || '',
      // yq_email: this.formData.yq_email || '',
      cw_name: this.formData.cw_name || '',
      cw_phone: this.formData.cw_phone || '',
      // cw_email: this.formData.cw_email || '',
      yj_name: this.formData.yj_name || '',
      yj_phone: this.formData.yj_phone || '',
      yj_address: this.formData.yj_address || '',
      eamil: this.formData.eamil || '',
    }
  },
  methods: {
    prevStep () {
      this.$emit('prevStep')
    },
    nextStep () {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit('nextStep', values)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item-with-help {
  margin-bottom: 20px;
}
</style>
