<template>
  <div>
    <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form" style="max-width: 500px; margin: 40px auto 0;">
      <a-form-item :label="stepData.role_id == 1 ? '企业法人' : '服务商'">
        <a-input
          type="text"
          v-decorator="['real_name', {initialValue: stepData.real_name, rules: [{required: true, message: '请输入企业法人'}]}]"
        />
      </a-form-item>
      <a-form-item :label="stepData.role_id == 1 ? '企业名称' : '服务商主体'">
        <a-input
          type="text"
          v-decorator="['company_name', {initialValue: stepData.company_name, rules: [{required: true, message: '请输入登录账号'}]}]"
        />
      </a-form-item>
      <a-form-item label="统一信用代码">
        <a-input
          type="text"
          v-decorator="['credit_code', {initialValue: stepData.credit_code, rules: [{required: true, message: '请输入统一信用代码'}]}]"
        />
      </a-form-item>
      <a-form-item label="手机号码">
        <a-input
          type="text"
          v-decorator="['phone', {initialValue: stepData.phone, rules: [{required: true, message: '请输入统一信用代码'}]}]"
        />
      </a-form-item>
      <a-form-item label="纳税人类型">
        <a-radio-group
        v-decorator="['type', {initialValue: stepData.type, rules: [{required: true, message: '请选择纳税人类型'}]}]"
        >
          <a-radio value="0">一般纳税人</a-radio>
          <a-radio value="1">小规模纳税人</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="行业类型">
        <a-input
          type="text"
          v-decorator="['industry_type', {initialValue: stepData.industry_type, rules: [{required: true, message: '请输入行业类型'}]}]"
        />
      </a-form-item>
      <!-- <a-form-item label="企业状态">
        <a-input
          type="text"
          v-decorator="['company_status', {initialValue: stepData.company_status, rules: [{required: true, message: '请输入企业状态'}]}]"
        />
      </a-form-item>
      <a-form-item label="注册资本">
        <a-input
          type="text"
          v-decorator="['money', {initialValue: stepData.money, rules: [{required: true, message: '请输入注册资本'}]}]"
        />
      </a-form-item>
      <a-form-item label="实缴资本">
        <a-input
          type="text"
          v-decorator="['real_money', {initialValue: stepData.real_money, rules: [{required: true, message: '请输入实缴资本'}]}]"
        />
      </a-form-item> -->
      <a-form-item label="注册地址">
        <a-input
          type="text"
          v-decorator="['address', {initialValue: stepData.address, rules: [{required: true, message: '请输入注册地址'}]}]"
        />
      </a-form-item>
      <!-- <a-form-item label="上市状态">
        <a-input
          type="text"
          v-decorator="['listed_status', {initialValue: stepData.listed_status, rules: [{required: true, message: '请输入上市状态'}]}]"
        />
      </a-form-item> -->
      <a-form-item label="开户银行">
        <a-input
          type="text"
          v-decorator="['bank', {initialValue: stepData.bank, rules: [{required: true, message: '请输入开户银行，填写到支行'}]}]"
        />
      </a-form-item>
      <a-form-item label="银行账号">
        <a-input
          type="text"
          v-decorator="['bank_code', {initialValue: stepData.bank_code, rules: [{required: true, message: '请输入银行账号'}]}]"
        />
      </a-form-item>
      <a-form-item label="服务商标签" v-if="stepData.role_id == 2">
        <a-checkbox-group @change="handleLableChange">
          <a-checkbox :value="item.id" v-for="item in lableData" :key="item.id" :checked="item.checked">{{item.name}}</a-checkbox>
        </a-checkbox-group>
        <a-input
          type="hidden"
          v-decorator="['lable', {initialValue: stepData.lable, rules: [{required: true, message: '请输入服务商标签，标签之间用`,`隔开'}]}]"
        />
      </a-form-item>
      <a-form-item label="服务商描述" v-if="stepData.role_id == 2">
        <a-textarea
          type="text"
          v-decorator="['introduction', {initialValue: stepData.introduction, rules: [{required: true, message: '请输入服务商描述'}]}]"
        />
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button size="large" type="primary" @click="prevStep">上一步</a-button>
        <a-button style="margin-left:8px;" size="large" type="primary" @click="nextStep">下一步</a-button>
        
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { lableData } from '@/utils/api'
export default {
  name: 'Step2',
  data () {
    return {
      stepData: {
        role_id: '',
        company_name: '',
        credit_code: '',
        phone: '',
        type: '',
        industry_type: '',
        real_name: '',
        // company_status: '',
        // money: '',
        // real_money: '',
        address: '',
        // listed_status: '',
        bank: '',
        bank_code: '',
        lable: '',
        introduction: ''
      },
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this),
      lableData: [],
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    this.getLableData()
    this.stepData = {
      role_id: this.formData.role_id || '',
      company_name: this.formData.company_name || '',
      credit_code: this.formData.credit_code || '',
      phone: this.formData.phone || '',
      type: this.formData.type || '0',
      industry_type: this.formData.industry_type || '',
      real_name: this.formData.real_name || '',
      // company_status: this.formData.company_status || '',
      // money: this.formData.money || '',
      // real_money: this.formData.real_money || '',
      address: this.formData.address || '',
      // listed_status: this.formData.listed_status || '',
      bank: this.formData.bank || '',
      bank_code: this.formData.bank_code || '',
      lable: this.formData.lable || '',
      introduction: this.formData.introduction || '',
      
    }
  },
  methods: {
    prevStep () {
      this.$emit('prevStep')
    },
    getLableData () {
      lableData({
        type: 2,
        switch: 4
      }).then((res) => {
        const data = res.info || []
        let checkedLable = this.stepData.lable
        if (checkedLable && data.length > 0) {
          const lableArr = checkedLable.split(',')
          for (let index = 0; index < data.length; index++) {
            const item = data[index];
            for (let i = 0; i < lableArr.length; i++) {
              const lable = lableArr[i];
              if (item.id == lable) {
                data[index].checked = true
                break
              }
            }
          }
        }
        this.lableData = data
      })
    },
    handleLableChange (e) {
      let lable = ''
      if (e.length > 0) {
        lable = ','
        for (let index = 0; index < e.length; index++) {
          const item = e[index];
          lable += item + ','
        }
      }
      this.stepData.lable = lable
    },
    nextStep () {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit('nextStep', values)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
