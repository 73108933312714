<template>
    <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form" style="max-width: 500px; margin: 40px auto 0;">
      <!-- <a-form-item label="单笔限额上限(人/元)">
        <a-input
          type="text"
          v-decorator="['d_money', {initialValue: stepData.d_money, rules: [{required: true, message: '请输入单笔限额上限'}]}]"
        />
      </a-form-item>
      <a-form-item label="每日限额上限(人/元)">
        <a-input
          type="text"
          v-decorator="['day_money', {initialValue: stepData.day_money, rules: [{required: true, message: '请输入每日限额上限'}]}]"
        />
      </a-form-item> -->
      <a-form-item label="月度限额上限(人/元)">
        <a-input
          type="text"
          v-decorator="['month_money', {initialValue: stepData.month_money, rules: [{required: true, message: '请输入月度限额上限'}]}]"
        />
      </a-form-item>
      <!-- <a-form-item label="年度限额上限(人/元)">
        <a-input
          type="text"
          v-decorator="['year_money', {initialValue: stepData.year_money, rules: [{required: true, message: '请输入年度限额上限'}]}]"
        />
      </a-form-item> -->
      <a-form-item label="含税服务费率">
        <a-input
          type="text"
          v-decorator="['hs_money', {initialValue: stepData.hs_money, rules: [{required: true, message: '请输入含税服务费率'}]}]"
          suffix="%"
        />
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button size="large" type="primary" @click="prevStep">上一步</a-button>
        <a-button style="margin-left:8px;" size="large" type="primary" @click="nextStep">下一步</a-button>
        
      </a-form-item>
    </a-form>
</template>

<script>
export default {
  name: 'Step5',
  data () {
    return {
      stepData: {
        // d_money: '',
        // day_money: '',
        month_money: '',
        // year_money: '',
        hs_money: '',
      },
      labelCol: { lg: { span: 8 }, sm: { span: 8 } },
      wrapperCol: { lg: { span: 16 }, sm: { span: 16 } },
      form: this.$form.createForm(this)
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    this.stepData = {
      // d_money: this.formData.d_money || '',
      // day_money: this.formData.day_money || '',
      month_money: this.formData.month_money || '',
      // year_money: this.formData.year_money || '',
      hs_money: this.formData.hs_money? (parseFloat(this.formData.hs_money) * 100) : '',
    }
  },
  methods: {
    prevStep () {
      this.$emit('prevStep')
    },
    nextStep () {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.hs_money = parseFloat(values.hs_money) / 100
          this.$emit('nextStep', values)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
