<template>
    <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form" style="max-width: 500px; margin: 40px auto 0;">
      <a-form-item label="真实姓名">
        <a-input
          type="text"
          v-decorator="['real_name', {initialValue: stepData.real_name, rules: [{required: true, message: '请输入真实姓名'}]}]"
        />
      </a-form-item>
      <a-form-item label="手机号码">
        <a-input
          type="text"
          v-decorator="['phone', {initialValue: stepData.phone, rules: [{required: true, message: '请输入手机号码'}]}]"
        />
      </a-form-item>
      <a-form-item label="统一信用代码">
        <a-input
          type="text"
          v-decorator="['id_code', {initialValue: stepData.id_code, rules: [{required: true, message: '请输入统一信用代码'}]}]"
        />
      </a-form-item>
      <a-form-item label="开户银行">
        <a-input
          type="text"
          v-decorator="['bank', {initialValue: stepData.bank, rules: [{required: true, message: '请输入开户银行，填写到支行'}]}]"
        />
      </a-form-item>
      <a-form-item label="银行账号">
        <a-input
          type="text"
          v-decorator="['bank_code', {initialValue: stepData.bank_code, rules: [{required: true, message: '请输入银行账号'}]}]"
        />
      </a-form-item>
      <a-form-item label="描述">
        <a-input
          type="text"
          v-decorator="['introduction', {initialValue: stepData.introduction, rules: [{required: true, message: '请输入描述'}]}]"
        />
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button size="large" type="primary" @click="prevStep">上一步</a-button>
        <a-button style="margin-left:8px;" size="large" type="primary" @click="nextStep">下一步</a-button>
        
      </a-form-item>
    </a-form>
</template>

<script>
export default {
  name: 'Step6',
  data () {
    return {
      stepData: {
        real_name: '',
        phone: '',
        id_code: '',
        bank: '',
        bank_code: '',
        introduction: ''
      },
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this)
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    this.stepData = {
      real_name: this.formData.real_name || '',
      phone: this.formData.phone || '',
      id_code: this.formData.id_code || '',
      bank: this.formData.bank || '',
      bank_code: this.formData.bank_code || '',
      introduction: this.formData.introduction || '',
      
    }
  },
  methods: {
    prevStep () {
      this.$emit('prevStep')
    },
    nextStep () {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit('finish', values)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
